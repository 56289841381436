import { memo } from 'react';
import { Link } from 'react-router-dom';


import RNavItem from './RNavItem';
import ChevronLeftIcon from 'Icons/ChevronLeftIcon';
import ChevronRightIcon from 'Icons/ChevronRightIcon';
import TableauBordIcon from 'Icons/TableauBordIcon';
import EnterpriseIcon from 'Icons/EnterpriseIcon';
import LicenceIcon from 'Icons/LicenceIcon';
import MaterielIcon from 'Icons/MaterielIcon';
import SupportIcon from 'Icons/SupportIcon';
import RevertoLogoIcon from 'Icons/RevertoLogoIcon';
import ParameterIcon from 'Icons/ParameterIcon';
import TokenIcon from 'Icons/TokenIcon';
import ClientIcon from 'Icons/ClientIcon';
import ProductionIcon from 'Icons/ProductionIcon';
import ReportIcon from 'Icons/ReportIcon';

import './style.css';
import { usePermissions } from 'features/auth/hooks/usePermissions';

const Navbar = ({ open = false, setOpen, isMobile = false }) => {
    const { isRevertoEmployee } = usePermissions();
    const logo_id = open ? "logo-open" : "logo-close";
    const support_button = open ? "support-button" : "support-button-close";

    return (
        <div id={"main-menu"}>
            {
                isMobile ? null :
                    open ?
                        <ChevronLeftIcon className='retract-icon' onClick={() => setOpen(!open)} />
                    :
                        <ChevronRightIcon className='retract-icon' onClick={() => setOpen(!open)} />
            }
            <Link to={"/"}>
                <RevertoLogoIcon height={"100%"} width={"100%"} className="main-logo" id={logo_id}/>
            </Link>
            {
                isRevertoEmployee() ?
                        <>
                            <MenuReverto open={open} />
                            <div className='version-site-web-container'>
                                v 0.9.96
                            </div>
                        </>
                    :
                        <>
                            <MenuClient open={open} />
                            <Link className="RNavItemLink" to={"/contact"} >
                                <div className={support_button}>
                                    <SupportIcon color="white" />
                                    <span>Contacter Reverto</span>
                                </div>
                            </Link>
                            <div className="version-site-web-container">
                                v 0.9.96
                            </div>
                        </>
            }
        </div>
    );
}

const MenuClient = ({ open }) => {
    return (
        <>
            <RNavItem open={open} text="Tableau de bord" link="/dashboard" icon={<TableauBordIcon color="white"/>} />
            <RNavItem open={open} text="Mon entreprise" link="/enterprise/utilisateurs" icon={<EnterpriseIcon color="white"/>} />
            <RNavItem open={open} text="Mes licences" link="/enterprise/licences" icon={<LicenceIcon color="white"/>} />
            <RNavItem open={open} text="Mes rapports" link="/enterprise/rapports" icon={<ReportIcon color='white' />} />
            <RNavItem open={open} text="Mon matériel" link="/enterprise/materiels" icon={<MaterielIcon color="white"/>} />
        </>
    )
}

const MenuReverto = ({ open }) => {
    return (
        <>
            <RNavItem open={open} text="Tableau de bord" link="/dashboard" icon={<TableauBordIcon color="white"/>} />
            <RNavItem open={open} text="Production" link="/contents" icon={<ProductionIcon color="white"/>} />
            <RNavItem open={open} text="Mon entreprise" link="/enterprise/utilisateurs" icon={<EnterpriseIcon color="white"/>} />
            <RNavItem open={open} text="Mes licences" link="/enterprise/licences" icon={<LicenceIcon color="white"/>} />
            <RNavItem open={open} text="Mes rapports" link="/enterprise/rapports" icon={<ReportIcon color='white' />} />
            <RNavItem open={open} text="Mon matériel" link="/enterprise/materiels" icon={<MaterielIcon color="white"/>} />
            <RNavItem open={open} text="Mes organisations" link="/enterprise/entreprises" icon={<ClientIcon color="white"/>} />
            <RNavItem open={open} text="Tokens" link="/tokens" icon={<TokenIcon color="white"/>} />
            <RNavItem open={open} text="Paramètres" link="/parameters" icon={<ParameterIcon color="white"/>} />
        </>
    )
}

export default memo(Navbar);


